import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS } from 'theme'
import Image from 'components/atoms/Image'

export const AVATAR_PLACEHOLDER = '/assets/avatar.svg'

const isImageValid = ({ source }) => {
  return new Promise((resolve) => {
    try {
      if (!source) {
        resolve(false)
      } else {
        const img = document.createElement('img')
        img.onerror = () => resolve(false)
        img.onload = () => resolve(true)
        img.src = source
      }
    } catch (e) {
      // ReferenceError: document is not defined
      resolve(true)
    }
  })
}

const ImageWithFallback = ({
  source,
  fallbackSource,
  alt,
  fallbackError,
  fallbackSourceWidth,
  fallbackSourceHeight,
  removeStyle,
  ...props
}) => {
  const [src, setSrc] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoading(true)
      isImageValid({ source }).then(async (isValid) => {
        if (!isValid) {
          if (fallbackSource) {
            setSrc(fallbackSource)
          } else if (fallbackError) {
            const src = await fallbackError()
            if (src) {
              setSrc(src)
            }
          }
        } else {
          setSrc(source)
        }
        setLoading(false)
      })
    }
  }, [source])

  const style = removeStyle
    ? {}
    : {
        transition: '0.5s filter linear',
        filter: `${loading ? 'blur(16px)' : ''}`,
        background: loading ? COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL : 'transparent',
      }

  if (fallbackSource === src) {
    if (fallbackSourceWidth) {
      style.width = fallbackSourceWidth
    }
    if (fallbackSourceHeight) {
      style.height = fallbackSourceHeight
    }
  }

  return (
    <Image
      style={style}
      // loading="lazy"
      src={src}
      alt={alt}
      onError={() => {
        setSrc(fallbackSource)
      }}
      {...props}
      objectFit={src && src.includes('/assets/') ? 'contain' : props.objectFit || 'cover'}
    />
  )
}

ImageWithFallback.defaultProps = {
  fallbackSource: AVATAR_PLACEHOLDER,
  source: AVATAR_PLACEHOLDER,
  alt: '',
  fallbackError: () => {},
  fallbackSourceWidth: null,
  fallbackSourceHeight: null,
  removeStyle: false,
}

ImageWithFallback.propTypes = {
  source: PropTypes.string,
  fallbackSource: PropTypes.oneOfType([PropTypes.string, () => null]),
  alt: PropTypes.string,
  fallbackError: PropTypes.func,
  fallbackSourceWidth: PropTypes.string,
  fallbackSourceHeight: PropTypes.string,
  removeStyle: PropTypes.bool,
}

export default ImageWithFallback
